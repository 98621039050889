import React from "react";
import img5 from "../../assets/img5.svg";
import img6 from "../../assets/img6.svg";
import { Button } from "bootstrap";
const AboutUs = () => {
  const Cards = [
    { img: img5, title: "FOR COLLEGE STUDENT", buttonText: "ALL STREAM" },
    { img: img6, title: "FOR SCHOOL STUDENT", buttonText: "10th to 12th" },
  ];
  return (
    <div id="about" className="w-full py-10 overflow-x-hidden bg-white">
      <div className="sm:w-[95%] md:w-[80%] mx-auto">
        <div
          style={{ border: "2px solid #F8F7F3" ,boxShadow:'0 0 8px rgba(0, 0, 0, 0.6)' }}
          className="flex bg-[#F8F7F3] rounded-[15px] gap-3 py-6 justify-center text-center font-poppins flex-col"
        >
          <div className="">
            <h1 className="text-13xl font-bold text-[#2F327D] ">
              Who Are{" "}
              <span className="text-[#2D938A] tracking-[1px]">InfoArbol</span>
            </h1>
          </div>
          <div className="text-[#696984] tracking-[2px] leading-7 ">
            <p>
              At InfoArbol Career Counseling, Our app is the trusted partner in
              navigating the confusing world of career choices. InfoArbol online
              career counselling india understands that finding the right career
              path can be overwhelming, and that's why we're here to provide
              expert guidance tailored to your unique needs
            </p>
          </div>
          <div className="flex sm:flex-col py-0 md:mt-[-42px]  md:flex-row">
            {Cards.map((card, index) => (
              <div key={index} className="relative cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out overflow-x-hidden w-screen">
                <img
                  srcSet={card.img}
                  className=" custom-dimensions"
                />
                <div className="absolute inset-0 font-poppins flex flex-row justify-center items-center text-white">
                  <div className="flex flex-col gap-3 text-center">
                    <p className="md:text-5xl sm:text-lg sm:text-center md:text-left my-[1px] font-bold">
                      {card.title}
                    </p>
                    <div className="bg-[#23BDEEE5] rounded-[80px] self-center w-fit">
                      <p className="md:text-lg cursor-pointer sm:text-mini   sm:px-6 md:px-12 text-center items-center place-content-center md:py-3 sm:py-1 my-auto  font-normal ">
                        {card.buttonText}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
